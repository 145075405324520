/* You can add global styles to this file, and also import other style files */
// ETS Color Pallets - use app-colors file

$white: #ffffff;
$black: #000000;

$ui-text: #565656;
$ui-icon: #737373;
$ui-border: #CCCCCC;
$ui-action: #0E3AD6;
$ui-success: #62A420;
$ui-danger: #E62700;

$persianblue-bright: #E7EBFB;
$persianblue-light: #B7C4F3;
$persianblue-soft: #6E89E6;
$persianblue-medium: #0E3AD6;
$persianblue-dark: #082380;
$persianblue-darker: #041140;

$lbb-bright: #F7FAFF;
$lbb-light: #E8EFFE;
$lbb-soft: #D0DFFD;
$lbb-medium: #80A8F8;
$lbb-dark: #6A7997;
$lbb-darker: #353D48;

$mustard-bright: #FFFBED;
$mustard-light: #FFF4CA;
$mustard-soft: #FFE895;
$mustard-medium: #FFD94E;
$mustard-dark: #CCAE3E;
$mustard-darker: #806D27;

$aquamarine-bright: #F1FBF5;
$aquamarine-light: #D5F2E2;
$aquamarine-soft: #AAE5C5;
$aquamarine-medium: #72D39F;
$aquamarine-dark: #447F5F;
$aquamarine-darker: #223F30;

$mangotango-bright: #fff3ec;
$mangotango-light: #fedac5;
$mangotango-soft: #fcb58c;
$mangotango-medium: #FA833F;
$mangotango-dark: #964f26;
$mangotango-darker: #4b2713;

$zen-bright: #F3F3F3;
$zen-light: #DFDFDF;
$zen-soft: #D1D1D1;
$zen-medium: #959595;
$zen-dark: #707070;
$zen-darker: #4D4D4D;

$bkrd-page: #FAFAFA;
$bkrd-active: #D9E4EA;
$bkrd-component: #FFFFFF;

.margin-top-0 {
    margin-top: 0;
}

.padding-0 {
    padding: 0;
}       
.convertToBlue {
    color: $persianblue-medium;
    font-weight: bold;
}
.convertToBold {
    font-weight: bold;
    color: $black;
}

.highlighted {
    background-color: $mustard-light;
    font-weight: bold;
}

.boldText {
    font-weight: 700;
}

button:focus-visible {
    outline: 2px solid $ui-action;
    outline-offset: 2px;
}

a:focus-visible {
    outline: 2px solid $ui-action;
    outline-offset: 2px;
}